import "./header.scss"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import * as React from "react"

const Header = ({ siteTitle }: { siteTitle: string }) => (
  <header className="main-header">
    <div className="main-header-container">
      <h1 style={{ margin: 0 }}>
        <Link to="/">
          <span className="main-logo">Priscko</span>
          <span className="main-title">{siteTitle}</span>
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: "",
}

export default Header
